<template>
    <div>
        <!-- TODO BB-879 Change titleEditRoute -->
        <BasicDetailHeaderComponent :promises.sync="promises" default-avatar="default-project-avatar.svg"
            :title="basicProject.projectName"
            :titleEditRoute="{ name: routeNames.PROJECT_DETAIL_EDIT_PROJECT_NAME, params: { projectId: projectId, clientId: clientId } }"
            :return-link-route="{ name: routeNames.PROJECT_OVERVIEW }" return-link-text="Terug naar projecten"
            :active="basicProject.active" active-text="Actief project" inactive-text="Inactief project"
            menu-button-text="Acties">

            <template v-slot:content>
                <v-col cols="12">
                    Id: <Editable :route="getEditIdRoute" class="notranslate">{{ basicProject.functionalId }}</Editable>
                </v-col>

                <v-col cols="12">
                    Klant: <Editable :route="editClientRoute" class="notranslate"><router-link :to="clientRoute"
                            class="clickable">{{ clientName }}</router-link></Editable>
                </v-col>
            </template>

            <template v-slot:actions>
                <PlanningHeaderAction
                    :query="{ clients: clientId, projects: projectId }"
                />
                <v-list-item link @click="onToggleActiveClick">
                    <v-list-item-icon><v-icon>{{ basicProject.active ? "mdi-folder-cancel" : "mdi-folder-check"
                            }}</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ basicProject.active ? "Zet op inactief" : "Zet op actief"
                        }}</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="onDeleteProjectClick">
                    <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                    <v-list-item-title>Verwijder</v-list-item-title>
                </v-list-item>
            </template>
        </BasicDetailHeaderComponent>

        <ConfirmationDialog :title="getToggleActiveDialogTitle" :description="getToggleActiveDialogDescription"
            v-model="toggleActiveDialog" :onConfirm="toggleActive" />
        <ConfirmationDialog :title="getDeleteDialogTitle" :description="getDeleteDialogDescription"
            v-model="deleteDialog" :onConfirm="deleteProject" />
    </div>
</template>

<script>
import BasicDetailHeaderComponent from '../shared/BasicDetailHeaderComponent.vue'
import routeNames from "@/router/RouteNames"
import ConfirmationDialog from "@/components/shared/ConfirmationDialog.vue"
import Editable from "@/components/shared/Editable"
import PlanningHeaderAction from "@/components/shared/actions/PlanningHeaderAction"

export default {
    name: "ProjectDetailHeaderComponent",
    components: {
        BasicDetailHeaderComponent,
        ConfirmationDialog,
        Editable,
        PlanningHeaderAction,
    },
    props: {
        clientId: {
            type: String,
            required: true
        },
        projectId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            promises: [
                this.fetchBasicProject(),
                this.$store.dispatch("clientsModule/fetchClientName", this.clientId)
                    .then(result => this.clientName = result)
            ],
            routeNames,
            basicProject: {
                projectName: null,
                active: null,
                functionalId: null
            },
            clientName: null,
            clientRoute: { name: routeNames.CLIENT_DETAIL, params: { clientId: this.clientId } },
            editClientRoute: { name: routeNames.PROJECT_DETAIL_EDIT_PROJECT_CLIENT, params: { clientId: this.clientId, projectId: this.projectId } },
            toggleActiveDialog: false,
            deleteDialog: false
        }
    },
    methods: {
        onToggleActiveClick() {
            this.toggleActiveDialog = true
        },
        toggleActive() {
            this.promises.push(
                this.$store.dispatch("projectsModule/toggleProjectActive", { clientId: this.clientId, projectId: this.projectId })
                    .then(() => this.promises.push(this.fetchBasicProject()))
            )
        },
        fetchBasicProject() {
            this.$store.dispatch("projectsModule/fetchBasicProject", { clientId: this.clientId, projectId: this.projectId })
                .then(result => this.basicProject = result)
        },
        onDeleteProjectClick() {
            this.deleteDialog = true
        },
        deleteProject() {
            this.promises.push(
                this.$store.dispatch("projectsModule/deleteProject", { clientId: this.clientId, projectId: this.projectId })
                    .then(() => this.$router.push(this.clientRoute))
            )
        },
    },
    computed: {
        getToggleActiveDialogTitle() {
            return `Project ${this.basicProject.projectName} ${this.basicProject.active ? "deactiveren" : "activeren"}`
        },
        getToggleActiveDialogDescription() {
            return `Bent u zeker dat u project ${this.basicProject.projectName} wilt ${this.basicProject.active ? "deactiveren" : "activeren"}?`
        },
        getDeleteDialogTitle() {
            return `Project ${this.basicProject.projectName} verwijderen`
        },

        getDeleteDialogDescription() {
            return `Bent u zeker dat u project ${this.basicProject.projectName} wilt verwijderen?`
        },
        getEditIdRoute() {
            return { name: routeNames.PROJECT_DETAIL_EDIT_FUNCTIONAL_ID, params: { clientId: this.clientId, projectId: this.projectId } }
        },
    }
}
</script>